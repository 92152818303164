// /*! -----------------------------------------------------------------------------------

//     Template Name: Cuba Admin
//     Template URI: http://admin.pixelstrap.com/cuba/theme
//     Description: This is Admin theme
//     Author: Pixelstrap
//     Author URI: https://themeforest.net/user/pixelstrap

// -----------------------------------------------------------------------------------

//  */

// // *** utils ***

// This are default CSS variables. They will be overwritten by docker-compose variables
:root {
    --theme-default: #00aeef;
    --theme-default-hover: #0089bc;
    --theme-secondary: #384e59;
    --theme-secondary-hover: #1e262b;
}

// // *** base ***
@import "base/reset";
@import "base/typography";


// //  *** components ***
@import "components/according";
// @import "components/alert";
// @import "components/avatars";
@import "components/badge";
// @import "components/bookmark";
// @import "components/breadcrumb";
// @import "components/builders";
@import "components/buttons";
@import "components/calendar";
@import "components/card";
// @import "components/color";
@import "components/datepicker";
@import "components/dropdown";
// @import "components/datatable";
// // @import "components/form-builder";
// @import "components/form-input";
// @import "components/form-wizard";
// @import "components/forms";
// // @import "components/form_builder-2";
@import "components/icons";
// @import "components/list";
@import "components/loader";
// @import "components/popover";
// @import "components/print";
@import "components/radio";
@import "components/ribbon";
// @import "components/switch";
@import "components/tab";
@import "components/table";
// @import "components/touchspin";
// @import "components/tour";
// @import "components/typeahead-search";
@import "components/react-plugin";
// @import "components/slick.scss";
// @import "components/slick-theme.scss";
// @import "components/scroll.scss";
// @import "components/timepicker";


// //	*** pages ***

// @import "pages/blog";
// @import "pages/bookmark-app";
// @import "pages/cart";
// @import "pages/chart";
// @import "pages/chat";
// @import "pages/checkout";
// @import "pages/comingsoon";
// @import "pages/contacts";
// @import "pages/dashboard_2";
// @import "pages/dashboard_default";
// @import "pages/ecommerce";
// @import "pages/email-application";
// @import "pages/errorpage";
// @import "pages/faq";
@import "pages/file";
// @import "pages/gallery";
// @import "pages/job-search";
@import "pages/kanban";
// @import "pages/knowledgebase";
// @import "pages/language";
// @import "pages/learning";
@import "pages/login";
// @import "pages/megaoption";
@import "pages/order-history";
// @import "pages/page";
@import "pages/pricing";
// @import "pages/progress";
@import "pages/projectlist";
@import "pages/social-app";
// @import "pages/task";
// @import "pages/timeline-v";
// @import "pages/timeliny";
// @import "pages/user-profile";
// @import "pages/wishlist";


// // *** layout *** 

@import "layout/footer";
@import "layout/grid";
@import "layout/header";
// @import "layout/search";
@import "layout/sidebar";
@import "layout/rtl";
@import "layout/box-layout";

// /* ---------------------
// 	*** themes ***
// -----------------------*/
// @import "themes/dark";
// @import "themes/dark-sidebar";
// @import "themes/theme-customizer";
@import "themes/responsive";